import React from 'react';
import styled from 'styled-components';

const Menu = styled.main`
  .items-container {
    padding: 10px;
    background-color: #ca3435;
    border-radius: 6px;
  }

  .menu-title {
    color: #f1c524;
    text-align: center;
    font-family: 'Yellowtail', cursive;
    font-weight: 700;
    font-size: 2.125rem;
    margin: 25px auto;
  }

  .menu-obs {
    p {
      font-size: 0.875rem;
      color: #ababab;
      font-weight: 600;
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .pizza-promo {
    text-align: center;
    color: #f1c524;
    font-size: 0.875rem;
  }

  @media (min-width: 768px) {
    max-width: 600px;
    margin: 0 auto;

    .items-container {
      padding: 20px;
    }
  }
`;

const Item = styled.div`
  margin-bottom: 40px;
  color: #fafafa;

  .title {
    text-transform: uppercase;
    font-weight: 400;
  }

  .price {
    font-size: 1.125rem;
    color: #f1c524;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      margin-right: 4px;
    }
  }

  .desc {
    font-size: 0.875rem;
    color: #c8c8c8;
    font-weight: 400;
  }

  .item-separator {
    border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
`;

export default function Esfihas() {
  return (
    <Menu>
      <h1 className="menu-title">Esfihas</h1>

      <div className="items-container">
        <Item>
          <div className="item-separator">
            <h4 className="title">01 - À Moda</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Carne Moída com Mussarela.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">02 - À Moda da Casa</h4>

            <h4 className="price">
              <span>R$</span>7,00
            </h4>
          </div>

          <p className="desc">
            Frango, Catupiry, Mussarela, Calabresa e Bacon.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">03 - Abobrinha</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Mussarela, Abobrinha e Parmesão.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">04 - Bacon</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Mussarela e Bacon.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">05 - Berinjela</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Mussarela, Berinjela e Parmesão.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">06 - Brócolis</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Mussarela, Brócolis, Bacon e Alho Frito.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">07 - Carne</h4>

            <h4 className="price">
              <span>R$</span>5,00
            </h4>
          </div>

          <p className="desc">Carne Moída Temperada com Tomates e Cebola.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">08 - Calabresa</h4>

            <h4 className="price">
              <span>R$</span>5,00
            </h4>
          </div>

          <p className="desc">Calabresa Moída.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">9 - Calabresa 2</h4>

            <h4 className="price">
              <span>R$</span>7,00
            </h4>
          </div>

          <p className="desc">
            Calabresa Moída e Temperada com Tomate e Cebola.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">10 - Costela</h4>

            <h4 className="price">
              <span>R$</span>8,00
            </h4>
          </div>

          <p className="desc">
            Costela, Catupiry, Mussarela, Tomate, Barbecue e Cebola.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">11 - Cupim</h4>

            <h4 className="price">
              <span>R$</span>8,00
            </h4>
          </div>

          <p className="desc">
            Cupim, Catupiry, Mussarela, Tomate, Barbecue e Cebola.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">12 - Escarola</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Escarola e Mussarela.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">13 - Esfihão</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Presunto e Queijo.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">14 - Frango com Catupiry</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Frango com Catupiry</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">15 - Frango Especial</h4>

            <h4 className="price">
              <span>R$</span>7,00
            </h4>
          </div>

          <p className="desc">
            Frango Temperado com Especiarias da Casa, Catupiry e Tomate Cereja.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">16 - FranPalha</h4>

            <h4 className="price">
              <span>R$</span>7,00
            </h4>
          </div>

          <p className="desc">Frango, Bacon, Cheddar e Batata Palha.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">17 - Lombo</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Lombo, Cebola e Catupiry.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">18 - Napolitana</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Mussarela, Tomate e Parmesão.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">19 - Mexicana</h4>

            <h4 className="price">
              <span>R$</span>7,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Calabresa fatiada, Pimentão, Bacon, Tomate, Parmesão e
            Alho frito.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">20 - Milho</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Mussarela, Milho e Bacon.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">21 - 4 Queijos</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Mussarela, Catupiry, Provolone e Parmesão.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">22 - Queijo</h4>

            <h4 className="price">
              <span>R$</span>5,00
            </h4>
          </div>

          <p className="desc">Mussarela</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">23 - Queijo 2</h4>

            <h4 className="price">
              <span>R$</span>7,00
            </h4>
          </div>

          <p className="desc">Queijo Temperado com Especiarias da Casa.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">24 - Palmito</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Mussarela e Palmito.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">25 - Peperoni</h4>

            <h4 className="price">
              <span>R$</span>7,00
            </h4>
          </div>

          <p className="desc">Mussarela e Peperoni.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">26 - Philadelphia</h4>

            <h4 className="price">
              <span>R$</span>7,00
            </h4>
          </div>

          <p className="desc">Peito de Peru e Cream Cheese.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">27 - Viena</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Atum e Mussarela.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">28 - Toscana</h4>

            <h4 className="price">
              <span>R$</span>6,00
            </h4>
          </div>

          <p className="desc">Mussarela e Calabresa Moída.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">29 - Torresmo</h4>

            <h4 className="price">
              <span>R$</span>7,00
            </h4>
          </div>

          <p className="desc">Mussarela, Torresmo e Bacon.</p>
        </Item>
      </div>
    </Menu>
  );
}

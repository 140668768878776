import React from 'react';
import styled from 'styled-components';

const Item = styled.div`
  margin-bottom: 40px;
  color: #fafafa;

  .title {
    text-transform: uppercase;
    font-weight: 400;
  }

  .price {
    font-size: 1.125rem;
    color: #f1c524;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      margin-right: 4px;
    }
  }

  .desc {
    font-size: 0.875rem;
    color: #ababab;
    font-weight: 400;
  }

  .item-separator {
    border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
`;

export default function Bordas() {
  return (
    <>
      <h1 className="menu-title">Bordas</h1>

      <Item>
        <div className="item-separator">
          <h4 className="title">Borda Tradicional</h4>

          <h4 className="price">
            <span>R$</span>14,00
          </h4>
        </div>

        <p className="desc">
          Cheddar, Catupiry, Mussarela, Cream Cheese, Creme de Avelã, Leite
          Ninho, Chocolate ao Leite ou Chocolate Branco.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="title">Borda formato Pan</h4>

          <h4 className="price">
            <span>R$</span>19,00
          </h4>
        </div>

        <p className="desc">Cream Cheese, Mussarela ou Cheddar.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="title">Borda formato Camarão</h4>

          <h4 className="price">
            <span>R$</span>19,00
          </h4>
        </div>

        <p className="desc">Cream Cheese, Mussarela ou Cheddar.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="title">Borda formato Vulcão</h4>

          <h4 className="price">
            <span>R$</span>19,00
          </h4>
        </div>

        <p className="desc">
          Cheddar, Catupiry, Mussarela, Cream Cheese, Creme de Avelã, Leite
          Ninho, Chocolate ao Leite ou Chocolate Branco.
        </p>
      </Item>
    </>
  );
}

import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import { InfoCircle } from 'styled-icons/boxicons-regular/InfoCircle';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Promotion from '../components/Promotion';
import Esfihas from '../components/Esfihas';
import Pizzas from '../components/Pizzas';
import Bordas from '../components/Bordas';

const Main = styled.main`
  max-width: 960px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
`;

const StyledAppBar = styled(AppBar)`
  background-color: #2e2b2d;
  color: #fafafa;
`;

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #f1c524;
  }

  .MuiTabs-scrollButtons {
    color: #f1c524;
  }

  /* @media (min-width: 768px) {
    .MuiTabs-flexContainer {
      justify-content: center;
      Only use if the number of tab don't need to scroll 
    }
  } */
`;

const Menu = styled.main`
  padding: 10px;

  .menu-title {
    color: #f1c524;
    text-align: center;
    font-family: 'Yellowtail', cursive;
    font-weight: 700;
    font-size: 2.125rem;
    margin: 25px auto;
  }

  .menu-obs {
    p {
      font-size: 0.875rem;
      color: #ababab;
      font-weight: 600;
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .pizza-promo {
    text-align: center;
    color: #f1c524;
    font-size: 0.875rem;
  }

  @media (min-width: 768px) {
    max-width: 600px;
    margin: 0 auto;
  }
`;

const Item = styled.div`
  margin-bottom: 40px;
  color: #fafafa;

  .title {
    text-transform: uppercase;
    font-weight: 400;
  }

  .price {
    font-size: 1.125rem;
    color: #f1c524;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      margin-right: 4px;
    }
  }

  .desc {
    font-size: 0.875rem;
    color: #ababab;
    font-weight: 400;
  }

  .item-separator {
    border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
`;

const IndexPage = () => {
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  return (
    <Layout>
      <SEO
        title="Cardápio"
        keywords={[
          `esfihas`,
          `esfihas doces`,
          `pizzas`,
          `pizzas doces`,
          `sucos`,
          `bebidas`,
          `disk entregas`,
        ]}
      />
      <Main>
        <Promotion />

        <StyledAppBar
          position="sticky"
          color="default"
          square={false}
          elevation={0}
        >
          <StyledTabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab label="Esfihas" />
            <Tab label="Esfihas Doces" />
            <Tab label="Pizzas" />
            <Tab label="Pizzas Doces" />
            <Tab label="Adicionais" />
            <Tab label="Bebidas" />
          </StyledTabs>
        </StyledAppBar>

        <SwipeableViews
          index={value}
          onChangeIndex={handleChangeIndex}
          animateHeight
        >
          <Esfihas />

          <Menu>
            <h1 className="menu-title">Esfihas Doces</h1>

            <Item>
              <div className="item-separator">
                <h4 className="title">30 - Abacaxi Nevado</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>

              <p className="desc">Mussarela, Abacaxi, Mel e Canela.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">31 - Banana Nevada</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>

              <p className="desc">
                Banana, Leite Condensado, Canela e Chocolate branco.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">32 - Brigadeiro</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>

              <p className="desc">Chocolate ao Leite e Granulado.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">33 - Bueno</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>

              <p className="desc">Chocolate, Avelã e Castanhas.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">34 - Chocolaka</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>

              <p className="desc">Chocolate Branco.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">35 - Confete</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>

              <p className="desc">Chocolate e Confete.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">36 - Kit Kat</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>

              <p className="desc">(Recheio a escolher).</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">37 - Mesclada</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>

              <p className="desc">Escolha o sabor.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">39 - Ninho</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>

              <p className="desc">Creme de Leite Ninho.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">40 - Prestígio</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>

              <p className="desc">Chocolate, Coco Ralado e Leite Condensado.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">41 - Romeu e Julieta</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>

              <p className="desc">Mussarela e Goiabada.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">42 - Sensação</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>

              <p className="desc">
                Creme de Abacaxi com Creme de Ninho e Creme de Morango (ou sabor
                a escolher).
              </p>
            </Item>

            <div className="menu-obs">
              <p>
                <InfoCircle size={26} /> Consulte valores e ingredientes de
                acréscimos.
              </p>
            </div>
          </Menu>

          <Pizzas />

          <Menu>
            <h1 className="menu-title">Pizzas Doces</h1>

            <div className="menu-obs">
              <p>
                <InfoCircle size={23} /> Todas nossas pizzas contém 8 pedaços.
              </p>
            </div>

            <Item>
              <div className="item-separator">
                <h4 className="title">49 - Abacaxi Nevado</h4>

                <h4 className="price">
                  <span>R$</span>49,00
                </h4>
              </div>

              <p className="desc" />
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">50 - Banana Nevada</h4>

                <h4 className="price">
                  <span>R$</span>49,00
                </h4>
              </div>

              <p className="desc">Banana, Canela e Chocolate branco.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">51 - Brigadeiro</h4>

                <h4 className="price">
                  <span>R$</span>47,00
                </h4>
              </div>

              <p className="desc">Chocolate ao Leite e Granulado.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">52 - Bueno</h4>

                <h4 className="price">
                  <span>R$</span>49,00
                </h4>
              </div>

              <p className="desc" />
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">53 - Chocolaka</h4>

                <h4 className="price">
                  <span>R$</span>47,00
                </h4>
              </div>

              <p className="desc">Chocolate Branco.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">54 - Confete</h4>

                <h4 className="price">
                  <span>R$</span>47,00
                </h4>
              </div>

              <p className="desc">Chocolate e Confete.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">55 - Kit Kat</h4>

                <h4 className="price">
                  <span>R$</span>49,00
                </h4>
              </div>

              <p className="desc">Escolha o Recheio.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">56 - Mesclada</h4>

                <h4 className="price">
                  <span>R$</span>47,00
                </h4>
              </div>

              <p className="desc">Chocolate Branco e Chocolate Preto.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">58 - Ninho</h4>

                <h4 className="price">
                  <span>R$</span>47,00
                </h4>
              </div>

              <p className="desc">Creme de Leite Ninho.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">59 - Prestígio</h4>

                <h4 className="price">
                  <span>R$</span>47,00
                </h4>
              </div>

              <p className="desc">Chocolate, Coco Ralado e Leite Condensado.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">60 - Romeu e Julieta</h4>

                <h4 className="price">
                  <span>R$</span>49,00
                </h4>
              </div>

              <p className="desc">Mussarela e Goiabada.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">61 - Sensação</h4>

                <h4 className="price">
                  <span>R$</span>49,00
                </h4>
              </div>

              <p className="desc">
                Creme de Abacaxi com Creme de Ninho e Creme de Morango (Ou sabor
                a escolher).
              </p>
            </Item>

            <div className="menu-obs">
              <p>
                <InfoCircle size={26} /> Consulte valores e ingredientes de
                acréscimos.
              </p>
            </div>

            <Bordas />
          </Menu>

          <Menu>
            <Bordas />

            <h1 className="menu-title">Adicionais</h1>

            <Item>
              <div className="item-separator">
                <h4 className="title">Adicional na Pizza inteira</h4>

                <h4 className="price">
                  <span>R$</span>10,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Adicional na meia Pizza</h4>

                <h4 className="price">
                  <span>R$</span>5,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Adicional na Esfiha</h4>

                <h4 className="price">
                  <span>R$</span>1,00
                </h4>
              </div>
            </Item>

            <div className="menu-obs">
              <p>
                <InfoCircle size={26} /> Consulte ingredientes de acréscimos
              </p>
            </div>
          </Menu>

          <Menu>
            <h1 className="menu-title">Bebidas</h1>

            <Item>
              <div className="item-separator">
                <h4 className="title">Suco Natural de Laranja 1L</h4>

                <h4 className="price">
                  <span>R$</span>17,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Suco Natural de Tangerina 1L</h4>

                <h4 className="price">
                  <span>R$</span>17,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerante 2L</h4>

                <h4 className="price">
                  <span>R$</span>10,00
                </h4>
              </div>

              <p className="desc">Xereta, Tubaina, Itubaina ou Gengibirra</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerante 2L Sprite</h4>

                <h4 className="price">
                  <span>R$</span>15,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerante 2L Pepsi</h4>

                <h4 className="price">
                  <span>R$</span>14,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">
                  Refrigerante 1L Coca Cola ou Coca Zero
                </h4>

                <h4 className="price">
                  <span>R$</span>10,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerante 1,5L Guaraná Antarctica</h4>

                <h4 className="price">
                  <span>R$</span>10,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerante 1,5L Fanta Laranja</h4>

                <h4 className="price">
                  <span>R$</span>10,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerantes Lata Coca Cola</h4>

                <h4 className="price">
                  <span>R$</span>6,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerantes Lata Coca Zero</h4>

                <h4 className="price">
                  <span>R$</span>6,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerantes Lata Sprite</h4>

                <h4 className="price">
                  <span>R$</span>6,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerantes Lata Fanta Laranja</h4>

                <h4 className="price">
                  <span>R$</span>6,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerantes Lata Fanta Uva</h4>

                <h4 className="price">
                  <span>R$</span>6,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerantes Lata Fanta Maracujá</h4>

                <h4 className="price">
                  <span>R$</span>6,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">H2Oh</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Água com Gás</h4>

                <h4 className="price">
                  <span>R$</span>4,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Água sem Gás</h4>

                <h4 className="price">
                  <span>R$</span>3,00
                </h4>
              </div>
            </Item>

            <h1 className="menu-title">Cervejas</h1>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Heineken 330ml</h4>

                <h4 className="price">
                  <span>R$</span>10,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Corona 330ml</h4>

                <h4 className="price">
                  <span>R$</span>10,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Budweiser 330ml</h4>

                <h4 className="price">
                  <span>R$</span>10,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Heineken Lata</h4>

                <h4 className="price">
                  <span>R$</span>7,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Brahma Duplo Malte Lata</h4>

                <h4 className="price">
                  <span>R$</span>5,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Brahma Lata</h4>

                <h4 className="price">
                  <span>R$</span>6,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Brahma 0% Lata</h4>

                <h4 className="price">
                  <span>R$</span>6,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Crystal Lata</h4>

                <h4 className="price">
                  <span>R$</span>5,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Império Lata</h4>

                <h4 className="price">
                  <span>R$</span>6,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Skol Lata</h4>

                <h4 className="price">
                  <span>R$</span>6,00
                </h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Skol Puro Malte Lata</h4>

                <h4 className="price">
                  <span>R$</span>6,00
                </h4>
              </div>
            </Item>
          </Menu>
        </SwipeableViews>
      </Main>
    </Layout>
  );
};

export default IndexPage;

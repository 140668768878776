import React from 'react';
import styled from 'styled-components';
import { InfoCircle } from 'styled-icons/boxicons-regular/InfoCircle';

import Bordas from './Bordas';

const Menu = styled.main`
  .items-container {
    padding: 10px;
    background-color: #f1c524;
    border-radius: 6px;
  }

  .menu-title {
    color: #f1c524;
    text-align: center;
    font-family: 'Yellowtail', cursive;
    font-weight: 700;
    font-size: 2.125rem;
    margin: 25px auto;
  }

  .menu-obs {
    p {
      font-size: 0.875rem;
      color: #ababab;
      font-weight: 600;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  .pizza-promo {
    text-align: center;
    color: #f1c524;
    font-size: 0.875rem;
  }

  @media (min-width: 768px) {
    max-width: 600px;
    margin: 0 auto;

    .items-container {
      padding: 20px;
    }
  }
`;

const Item = styled.div`
  margin-bottom: 40px;
  color: #ca3435;

  .title {
    text-transform: uppercase;
    font-weight: 400;
  }

  .price {
    font-size: 1.125rem;
    color: #ca3435;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      margin-right: 4px;
    }
  }

  .desc {
    font-size: 0.875rem;
    color: #333333;
    font-weight: 400;
  }

  .item-separator {
    border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
`;

export default function Pizzas() {
  return (
    <Menu>
      <h1 className="menu-title">Pizzas</h1>

      <p className="pizza-promo">
        Promoção de Pizzas todos os dias. <br />
        Confira nossas Redes Sociais!
      </p>

      <div className="menu-obs">
        <p>
          <InfoCircle size={23} /> Todas nossas pizzas contém 8 pedaços.
        </p>
      </div>

      <div className="items-container">
        <Item>
          <div className="item-separator">
            <h4 className="title">01 - Abobrinha</h4>

            <h4 className="price">
              <span>R$</span>48,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Abobrinha, Parmesão, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">02 - Atum</h4>

            <h4 className="price">
              <span>R$</span>51,00
            </h4>
          </div>

          <p className="desc">
            Atum, Mussarela, Palmito, Bacon, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">03 - Americana</h4>

            <h4 className="price">
              <span>R$</span>49,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Presunto, Palmito, Ervilhas, Milho, Bacon, Azeitonas e
            Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">04 - À Moda da Casa</h4>

            <h4 className="price">
              <span>R$</span>54,00
            </h4>
          </div>

          <p className="desc">
            Frango, Catupiry, Mussarela, Calabresa, Bacon, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">05 - À Moda do Pizzaiolo</h4>

            <h4 className="price">
              <span>R$</span>51,00
            </h4>
          </div>

          <p className="desc">
            Lombo Canadense, Frango, Mussarela, Champignon e Palmito.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">06 - Ariela</h4>

            <h4 className="price">
              <span>R$</span>48,00
            </h4>
          </div>

          <p className="desc">
            Lombo Canadense, Calabresa, Palmito, Bacon, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">07 - Bacon</h4>

            <h4 className="price">
              <span>R$</span>48,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Bacon, Cebola, Tomate, Orégano e Azeitona.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">08 - Baianinha</h4>

            <h4 className="price">
              <span>R$</span>48,00
            </h4>
          </div>

          <p className="desc">
            Calabresa Moída, Mussarela, Pimenta Calabresa, Pimenta Biquinho,
            Tomate, Azeitona e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">09 - Berinjela</h4>

            <h4 className="price">
              <span>R$</span>48,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Berinjela Gratinada, Parmesão e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">10 - Benvenutte</h4>

            <h4 className="price">
              <span>R$</span>51,00
            </h4>
          </div>

          <p className="desc">
            Presunto, Mussarela, Calabresa, Ovos, Azeitonas, Orégano e Alho
            Frito.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">11 - Brócolis</h4>

            <h4 className="price">
              <span>R$</span>48,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Brócolis, Catupiry, Bacon, Azeitonas, Orégano e Alho
            Frito.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">12 - Caipira</h4>

            <h4 className="price">
              <span>R$</span>51,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Frango, Tomate, Milho, Cebola, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">13 - Calabresa 1</h4>

            <h4 className="price">
              <span>R$</span>45,00
            </h4>
          </div>

          <p className="desc">Calabresa, Cebola, Azeitonas e Orégano.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">14 - Calabresa 2</h4>

            <h4 className="price">
              <span>R$</span>51,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Calabresa, Cebola, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">15 - Calabresa 3</h4>

            <h4 className="price">
              <span>R$</span>51,00
            </h4>
          </div>

          <p className="desc">
            Catupiry, Calabresa, Cebola, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">16 - Canadense</h4>

            <h4 className="price">
              <span>R$</span>56,00
            </h4>
          </div>

          <p className="desc">
            Lombo, Requeijão, Mussarela, Milho, Bacon e Ovo.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">17 - Costela</h4>

            <h4 className="price">
              <span>R$</span>62,00
            </h4>
          </div>

          <p className="desc">
            Costela, Catupiry, Mussarela, Tomate, Barbecue e Cebola.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">18 - Cupim</h4>

            <h4 className="price">
              <span>R$</span>62,00
            </h4>
          </div>

          <p className="desc">
            Cupim, Catupiry, Mussarela, Tomate, Barbecue e Cebola.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">19 - Da Avó</h4>

            <h4 className="price">
              <span>R$</span>48,00
            </h4>
          </div>

          <p className="desc">
            Lombo, Mussarela, Palmito, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">20 - Escarola</h4>

            <h4 className="price">
              <span>R$</span>49,00
            </h4>
          </div>

          <p className="desc">Escarola, Mussarela, Azeitonas e Orégano.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">21 - FranBacon</h4>

            <h4 className="price">
              <span>R$</span>54,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Frango em Cubos, Bacon, Tomate Cereja, Azeitonas e
            Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">22 - Frango 1</h4>

            <h4 className="price">
              <span>R$</span>49,00
            </h4>
          </div>

          <p className="desc">Frango, Catupiry, Azeitonas e Orégano.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">23 - Frango 2</h4>

            <h4 className="price">
              <span>R$</span>51,00
            </h4>
          </div>

          <p className="desc">Frango, Mussarela, Azeitonas e Orégano.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">24 - Frango 3</h4>

            <h4 className="price">
              <span>R$</span>54,00
            </h4>
          </div>

          <p className="desc">
            Frango, Cream Cheese, Tomate Cereja, Mussarela, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">25 - FranPalha</h4>

            <h4 className="price">
              <span>R$</span>54,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Frango, Bacon, Cheddar, Batata Palha, Azeitonas e
            Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">26 - Francesa</h4>

            <h4 className="price">
              <span>R$</span>54,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Peito de Peru, Cebola, Tomate, Catupiry e Parmesão.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">27 - Havaiana</h4>

            <h4 className="price">
              <span>R$</span>52,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Peito de Peru, Abacaxi, e Molho. (Escolher molho de
            tomate ou creme de leite).
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">28 - Jardineira</h4>

            <h4 className="price">
              <span>R$</span>52,00
            </h4>
          </div>

          <p className="desc">
            Presunto, Catupiry, Mussarela, Ovos, Bacon, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">29 - Lombo</h4>

            <h4 className="price">
              <span>R$</span>48,00
            </h4>
          </div>

          <p className="desc">
            Lombo, Catupiry, Mussarela, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">30 - Marguerita</h4>

            <h4 className="price">
              <span>R$</span>48,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Tomate, Parmesão, Manjericão, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">31 - Milho</h4>

            <h4 className="price">
              <span>R$</span>48,00
            </h4>
          </div>

          <p className="desc">Mussarela, Milho, Bacon e Orégano.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">32 - Mexicana 1</h4>

            <h4 className="price">
              <span>R$</span>50,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Calabresa, Pimentão, Bacon, Tomate, Parmesão, Azeitonas,
            Orégano e Alho Frito.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">33 - Mexicana 2</h4>

            <h4 className="price">
              <span>R$</span>55,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Calabresa, Pimenta Calabresa, Palmito, Bacon, Peperoni,
            Tomate, Parmesão, Azeitonas, Orégano e Alho Frito.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">34 - Mussarela</h4>

            <h4 className="price">
              <span>R$</span>45,00
            </h4>
          </div>

          <p className="desc">Mussarela, Tomate, Azeitonas e Orégano.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">35 - Palmito</h4>

            <h4 className="price">
              <span>R$</span>48,00
            </h4>
          </div>

          <p className="desc">Mussarela, Palmito, Azeitonas e Orégano.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">36 - Portuguesa</h4>

            <h4 className="price">
              <span>R$</span>49,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Presunto, Ervilhas, Ovos, Cebola, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">37 - Peperoni 1</h4>

            <h4 className="price">
              <span>R$</span>51,00
            </h4>
          </div>

          <p className="desc">Mussarela, Peperoni, Orégano e Azeitonas.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">38 - Peperoni 2</h4>

            <h4 className="price">
              <span>R$</span>55,00
            </h4>
          </div>

          <p className="desc">
            Cream Cheese, Mussarela, Peperoni, Orégano e Azeitonas.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">39 - Philadelphia</h4>

            <h4 className="price">
              <span>R$</span>52,00
            </h4>
          </div>

          <p className="desc">
            Peito de Peru, Cream Cheese, Mussarela, Orégano e Azeitonas.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">40 - Presunto e Queijo</h4>

            <h4 className="price">
              <span>R$</span>45,00
            </h4>
          </div>

          <p className="desc">
            Presunto, Mussarela, Tomate, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">41 - Quatro Queijos</h4>

            <h4 className="price">
              <span>R$</span>51,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Catupiry, Provolone, Parmesão, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">42 - Cinco Queijos</h4>

            <h4 className="price">
              <span>R$</span>55,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Catupiry, Provolone, Parmesão, Gorgonzola, Azeitonas e
            Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">43 - Salame</h4>

            <h4 className="price">
              <span>R$</span>55,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Salame, Parmesão, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">44 - Vegetariana</h4>

            <h4 className="price">
              <span>R$</span>51,00
            </h4>
          </div>

          <p className="desc">
            Escarola, Mussarela, Ervilha, Palmito, Milho, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">45 - Texana</h4>

            <h4 className="price">
              <span>R$</span>65,00
            </h4>
          </div>

          <p className="desc">
            Molho, Mussarela, Alcatra, Cebola, Tomate e Azeitona.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">46 - Torresmo</h4>

            <h4 className="price">
              <span>R$</span>52,00
            </h4>
          </div>

          <p className="desc">
            Mussarela, Bacon, Torresmo, Azeitonas e Orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">47 - Tropical Curry</h4>

            <h4 className="price">
              <span>R$</span>54,00
            </h4>
          </div>

          <p className="desc">
            Muçarela, presunto, abacaxi, banana, mel, pó em curry azeitonas e
            orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="title">48 - Pizza MK</h4>

            <h4 className="price">
              <span>R$</span>62,00
            </h4>
          </div>

          <p className="desc">Monte sua Pizza com até 5 ingredientes.</p>
        </Item>
      </div>

      <div className="menu-obs">
        <p>
          <InfoCircle size={26} /> Todas nossas pizzas salgadas contém Molho.
        </p>
      </div>

      <Bordas />
    </Menu>
  );
}
